<template>
  <div class="overflow-auto">
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th></th>
          <th>СЦ</th>
          <th>№</th>
          <th>дата продажи</th>
          <th>дата принятия в ремонт</th>
          <th>серийный номер</th>
          <th>продавец</th>
          <th>дефект</th>
          <th>проведенные работы</th>
          <th>гарантия</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in repairs_list" :key="idx + 'rlst'">
          <td @click="go_repair(item.remont_id)">{{ idx + 1 }}</td>
          <td @click="go_repair(item.remont_id)" class="text-nowrap">
            {{ item.user }}
          </td>
          <td @click="go_repair(item.remont_id)" class="text-nowrap">
            {{ item.repair_number }}
          </td>
          <td @click="go_repair(item.remont_id)">{{ item.dateSaleFormat }}</td>
          <td @click="go_repair(item.remont_id)">
            {{ item.dateRepairFormat }}
          </td>
          <td @click="go_repair(item.remont_id)" class="text-nowrap">
            {{ `${item.serialNumberGroup} ${item.serialNumber}` }}
          </td>
          <td @click="go_repair(item.remont_id)">{{ item.seller }}</td>
          <td @click="go_repair(item.remont_id)">{{ item.defect }}</td>
          <td
            @click="go_repair(item.remont_id)"
            v-html="`${type(item).title} ${item.workCarried || ''}`"
          ></td>
          <td @click="go_repair(item.remont_id)" class="text-nowrap">
            <span class="badge badge-danger p-1 w-100" v-if="item.guarantee"
              >не гарантия</span
            >
            <span
              class="badge p-1 w-100"
              :class="type(item).badge_type"
              v-else
              >{{ type(item).badge_text }}</span
            >
          </td>
          <td class="text-right photo">
            <i
              class="bi bi-camera"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="get_photo(item.id, idx)"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
    <Photo @next="get_photo" :repairs_list="repairs_list" :photo="photo" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { HTTPGET } from "@/api";
import Photo from "./Photo.vue";
export default {
  name: "ListInstrTable",
  props: ["repairs_list"],
  components: {
    Photo
  },
  computed: {
    ...mapGetters(["USER_INFO", "STATS_PAGE_SCROLL2"])
  },
  data: () => ({
    photo: null
  }),
  mounted() {
    window.scrollBy(0, this.STATS_PAGE_SCROLL2);
    this.set_stats_page_scroll2(0);
  },
  methods: {
    ...mapMutations(["set_stats_page_scroll2"]),
    type(item) {
      let str = {};
      if (item.typeOfRepair === "noRepair") {
        if (item.noRepairType === "1") {
          str.badge_type = "badge-primary";
          str.badge_text = "акт на возврат";
          str.title = `отказ от ремонта в течении 15 дней<br>`;
        }
        if (item.noRepairType === "2") {
          str.badge_type = "badge-primary";
          str.badge_text = "акт на возврат";
          str.title = `отказ от ремонта в течении года только сети<br>`;
        }
        if (item.noRepairType === "3") {
          str.badge_type = "badge-primary";
          str.badge_text = "акт на возврат";
          str.title = `отсутствие зч<br>`;
        }
        if (item.noRepairType === "4") {
          str.badge_type = "badge-primary";
          str.badge_text = "акт на возврат";
          str.title = `${item.noRepairTypeOther}<br>`;
        }
        return str;
      }
      if (item.typeOfRepair === "repair") {
        str.badge_type = "badge-success";
        str.badge_text = "ремонт";
        str.title = ``;

        return str;
      }
      if (item.typeOfRepair === "diagnostiks") {
        if (item.no_defect === "0") {
          str.badge_type = "badge-warning";
          str.badge_text = "диагностика";
          str.title = ``;
        } else {
          str.badge_type = "badge-secondary";
          str.badge_text = "нет дефекта";
          str.title = ``;
        }
        return str;
      }
    },
    go_repair(id) {
      if (this.USER_INFO.status === "admin") {
        this.$router.push(`/repairs/repair/${id}`);
        this.set_stats_page_scroll2(window.pageYOffset);
      }
    },
    get_photo(id, idx) {
      HTTPGET({
        url: "/stats/data_stats/get_photo.php",
        params: {
          id
        }
      }).then(response => {
        this.photo = response.data.photo;
        this.photo.idx = idx;
      });
    }
  }
};
</script>
<style scoped>
table {
  font-size: 0.7em;
}
th,
td {
  vertical-align: middle;
}
i {
  font-size: 2em;
  cursor: pointer;
}
.badge {
  font-size: 1em;
}
</style>
