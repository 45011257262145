<template>
  <div class="overflow-auto">
    <table>
      <tr v-for="(item, idx) in list_zap" :key="idx + 'lzt'">
        <td>{{ idx + 1 }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.cod }}</td>
        <td>{{ item.art }}</td>
        <td>{{ item.pcs }} шт.</td>
        <td>{{ item.cena }} &#8381;</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "Zap",
  props: ["list_zap"]
};
</script>
<style scoped>
table {
  font-size: 0.7em;
  margin-bottom: 10px;
  margin: auto;
  margin-bottom: 10px;
}
td {
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;
  padding: 3px;
}
</style>
