<template>
  <div class="wrapper overflow-auto">
    <div>
      <button
        class="back btn btn-sm btn-outline-secondary shadow-none"
        @click="back"
        disabled
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
    </div>
    <InstrInfo v-if="instr_info" :instr_info="instr_info" />
    <div v-if="s" class="mb-2">
      <span
        v-if="s.repair.count > 0"
        class="badge badge-success mr-1 mb-1 p-2"
        >{{ `ремонты ${s.repair.count} шт. ${s.repair.percent}%` }}</span
      >
      <span
        v-if="s.diagnostiks.count > 0"
        class="badge badge-warning mr-1 mb-1 p-2"
        >{{
          `диагностики ${s.diagnostiks.count} шт. ${s.diagnostiks.percent}%`
        }}</span
      >
      <span
        v-if="s.noRepair.count > 0"
        class="badge badge-primary mr-1 mb-1 p-2"
        >{{ `акты ${s.noRepair.count} шт. ${s.noRepair.percent}%` }}</span
      >
      <span
        v-if="s.guarantee.count > 0"
        class="badge badge-danger mr-1 mb-1 p-2"
        >{{
          `не гарантия ${s.guarantee.count} шт. ${s.guarantee.percent}%`
        }}</span
      >
      <span
        v-if="s.no_defect.count > 0"
        class="badge badge-secondary mr-1 mb-1 p-2"
        >{{
          `нет дефекта ${s.no_defect.count} шт. ${s.no_defect.percent}%`
        }}</span
      >
      <span
        class="badge badge-info mr-1 mb-1 p-2 cursor"
        @click.prevent="visible_zap"
      >
        список зч использованных для ремонта
      </span>
      <select
        class="form-control form-control-sm shadow-none col-md-2 col-sm-8 d-inline m-1"
        v-model="filter"
        @change="get_data"
      >
        <option value="all">все</option>
        <option value="repair">ремонты</option>
        <option value="diagnostiks">диагностики</option>
        <option value="noRepair">акты</option>
        <option v-if="!STATS_EXCLUDE_WORKING" value="no_defect"
          >нет дефекта</option
        >
        <option v-if="!STATS_EXCLUDE_WORKING" value="no_guarantee"
          >не гарантия</option
        >
      </select>
    </div>
    <Zap v-if="is_visible_zap && s && s.list_zap" :list_zap="s.list_zap" />
    <ListInstrTable v-if="repairs_list" :repairs_list="repairs_list" />
    <div><Loader v-if="loading" /></div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { HTTPGET } from "../../../api";
import InstrInfo from "./components/InstrInfo.vue";
import ListInstrTable from "./components/ListInstrTable.vue";
import Zap from "./components/Zap.vue";
import Loader from "@/components/Loader";
export default {
  name: "OneInstr",
  components: {
    InstrInfo,
    ListInstrTable,
    Zap,
    Loader
  },
  computed: {
    ...mapGetters([
      "STATS_FILTER",
      "STATS_EXCLUDE_WORKING",
      "STATS_SELECT_OPTIONS"
    ]),
    filter: {
      get() {
        return this.STATS_SELECT_OPTIONS;
      },
      set(value) {
        this.set_stats_select_options(value);
      }
    }
  },
  data: () => ({
    instr_info: null,
    repairs_list: null,
    s: null,
    is_visible_zap: false,
    loading: false
  }),
  mounted() {
    this.parse_data();
    this.get_data();
    console.log(this.$route);
  },
  methods: {
    ...mapMutations(["set_stats_select_options"]),
    back() {
      //if (this.GET_PATH_FROM === "/login") {
      //  this.$router.push("/home");
      //} else {
      //  this.$router.go(-1);
      //}
    },
    parse_data() {
      this.instr_info = JSON.parse(this.$route.query.data);
    },
    get_data() {
      this.loading = true;
      HTTPGET({
        url: "/stats/data_stats/get_one_instr_info.php",
        params: {
          year: this.STATS_FILTER.year,
          serialNumberGroup: this.instr_info.serialNumberGroup,
          cod: this.instr_info.cod,
          exclude_working: this.STATS_EXCLUDE_WORKING,
          select_options: this.STATS_SELECT_OPTIONS
        }
      })
        .then(response => {
          this.repairs_list = response.data.repairs_list;
          this.s = response.data.s;
        })
        .then(() => (this.loading = false));
    },
    visible_zap() {
      this.is_visible_zap = !this.is_visible_zap;
    }
  }
};
</script>
<style scoped>
.wrapper {
  padding: 10px;
}
.alert {
  font-size: 0.7em;
}
.cursor {
  cursor: pointer;
}
</style>
