<template>
  <div
    class="alert alert-primary d-flex justify-content-center text-center mt-1 flex-wrap w-100"
    role="alert"
  >
    <div class="m-1">
      <div>наименование инструмента</div>
      <div>{{ instr_info.name_instr }}</div>
    </div>
    <div class="m-1">
      <div>группа ремонт</div>
      <div>{{ instr_info.serialNumberGroup }}</div>
    </div>
    <div class="m-1">
      <div>группа</div>
      <div>{{ instr_info.gruppa }}</div>
    </div>
    <div class="m-1">
      <div>код</div>
      <div>{{ instr_info.cod }}</div>
    </div>
    <div class="m-1">
      <div>продано</div>
      <div>{{ instr_info.pcs_sale }} шт.</div>
    </div>
    <div class="m-1">
      <div>ремонт</div>
      <div>{{ instr_info.pcs_repair }} шт.</div>
    </div>
    <div class="m-1">
      <div>%</div>
      <div>{{ instr_info.percent }} %</div>
    </div>
    <div class="m-1">
      <div>зч</div>
      <div>{{ instr_info.summ_zap }} &#8381;</div>
    </div>
    <div class="m-1">
      <div>работы</div>
      <div>{{ instr_info.summ_rabot }} &#8381;</div>
    </div>
    <div class="m-1">
      <div>сумма</div>
      <div>{{ instr_info.summ }} &#8381;</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InstrInfo",
  props: ["instr_info"]
};
</script>
<style scoped></style>
