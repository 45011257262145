<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header m-1 p-1 justify-content-center" v-if="photo">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center flex-wrap">
              <li class="page-item">
                <a
                  class="page-link shadow-none"
                  :href="
                    selected_url
                      ? base_url + selected_url
                      : base_url + this.photo_list[this.selected_id].url
                  "
                  target="_blank"
                  >сохранить выбранную фотографию</a
                >
              </li>
              <li class="page-item" :class="photo.idx === 0 ? 'disabled' : ''">
                <a
                  class="page-link shadow-none"
                  href="g"
                  @click.prevent="next(photo.idx, 'prev')"
                  >предыдущий</a
                >
              </li>
              <li class="page-item">
                <a class="page-link shadow-none" href="g">{{
                  `${photo.idx + 1} из ${repairs_list.length}`
                }}</a>
              </li>
              <li
                class="page-item"
                :class="photo.idx + 1 === repairs_list.length ? 'disabled' : ''"
              >
                <a
                  class="page-link shadow-none"
                  href="g"
                  @click.prevent="next(photo.idx, 'next')"
                  >следующий</a
                >
              </li>
              <li class="page-item">
                <a
                  class="page-link shadow-none"
                  href="g"
                  data-dismiss="modal"
                  aria-label="Close"
                  >закрыть</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <div class="modal-body" v-if="photo">
          <div
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <div>
              <div class="text-center">
                {{ repairs_list[photo.idx].defect }}
              </div>
              <div
                class="text-center"
                v-html="`${type(repairs_list[photo.idx])}`"
              ></div>
            </div>
            <img
              @click="visible_big_photo"
              class="bf cursor"
              :src="base_url + photo_list[selected_id].url"
              alt=""
              :class="is_visible_big_photo ? 'vbf' : ''"
            />
          </div>
        </div>
        <div
          class="modal-footer justify-content-center overflow-auto"
          v-if="photo"
        >
          <div
            class="cursor"
            :class="item.id === selected_id ? 'selected' : ''"
            v-for="(item, idx) in photo_list"
            :key="idx + 'f1'"
          >
            <img :src="base_url + item.url" alt="" @click="selected(item)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import u from "@/constants/";
export default {
  name: "Photo",
  props: ["photo", "repairs_list"],
  computed: {
    photo_list: function() {
      let arr = [];
      let i = 0;
      if (this.photo.labelFoto) {
        this.photo.labelFoto.map(item => {
          arr.push({ url: item.path, id: i });
          i++;
        });
      }
      if (this.photo.ticketFoto) {
        this.photo.ticketFoto.map(item => {
          arr.push({ url: item.path, id: i });
          i++;
        });
      }
      if (this.photo.viewFoto) {
        this.photo.viewFoto.map(item => {
          arr.push({ url: item.path, id: i });
          i++;
        });
      }
      if (this.photo.defectDetailFoto) {
        this.photo.defectDetailFoto.map(item => {
          arr.push({ url: item.path, id: i });
          i++;
        });
      }
      return arr;
    }
  },
  data: () => ({
    selected_id: 0,
    selected_url: null,
    is_visible_big_photo: false,
    base_url: u.url
  }),
  methods: {
    selected(item) {
      this.selected_id = item.id;
      this.selected_url = item.url;
    },
    visible_big_photo() {
      this.is_visible_big_photo = !this.is_visible_big_photo;
    },
    next(idx, type) {
      if (type === "next" && idx + 1 < this.repairs_list.length) {
        this.$emit("next", this.repairs_list[idx + 1].id, idx + 1);
      }
      if (type === "prev" && idx > 0) {
        this.$emit("next", this.repairs_list[idx - 1].id, idx - 1);
      }
    },
    type(item) {
      let str = "";
      if (item.typeOfRepair === "noRepair") {
        if (item.noRepairType === "1") {
          str = `<b>акт на возврат</b><br>отказ от ремонта в течении 15 дней`;
        }
        if (item.noRepairType === "2") {
          str = `<b>акт на возврат</b><br>отказ от ремонта в течении года только сети`;
        }
        if (item.noRepairType === "3") {
          str = `<b>акт на возврат</b><br>отсутствие зч`;
        }
        if (item.noRepairType === "4") {
          str = `<b>акт на возврат</b><br>${item.noRepairTypeOther}`;
        }
        return str;
      }
      if (item.typeOfRepair === "repair") {
        return `<b>ремонт</b><br>${item.workCarried}`;
      }
      if (item.typeOfRepair === "diagnostiks") {
        return `<b>диагностика</b><br>${item.workCarried}`;
      }
    }
  }
};
</script>
<style scoped>
.modal-xl {
  height: 90%;
}
img {
  width: 10vh;
}
.modal-footer {
  overflow-x: auto;
}
.selected {
  -webkit-box-shadow: 8px 9px 29px -13px rgba(34, 60, 80, 1);
  -moz-box-shadow: 8px 9px 29px -13px rgba(34, 60, 80, 1);
  box-shadow: 8px 9px 29px -13px rgba(34, 60, 80, 1);
}
.big {
  height: 100%;
  width: 100%;
}
.bf {
  display: block;
  margin: auto;
  top: 15%;
  width: 65%;
}
.cursor {
  cursor: pointer;
}
.vbf {
  position: absolute;
  display: block;
  margin: auto;
  top: 5px;
  width: 98%;
}
</style>
